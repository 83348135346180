/*
VA-Team
 */

/*
Icon for mapbox control 'layers'
*/
span.mapbox-ctrl-icon.span.map-layers {
    background-image: url(logo-buffer.svg);
}
